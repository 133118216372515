<template>

  <div class="FeedDetail">

    <Head></Head>
    <div class="info">

      <div class="back">
        <router-link to="/user/userFeedback">
          <span @click="backSch">返回</span>
        </router-link>
        <em>/</em>
        <span>反馈详情</span>
      </div>

      <h1>{{ feedInfo.title }}</h1>
      <div class="bar">
        <ul>
          <li>{{ feedInfo.pubdata }}</li>
          <li>来源：{{ feedInfo.author }} [ID:{{feedInfo.aut_id}}] <em>/</em>   {{feedInfo.department}}</li>
          <li>浏览:{{ feedInfo.view }}</li>
          <li><div class="tag">{{feedInfo.tag}}</div></li>
        </ul>
      </div>
    </div>
    <div class="content">
      <p>{{ feedInfo.content }}</p>
    </div>
    <!-- <div class="content">
        <p v-html="feedInfo.content"></p>
    </div> -->
    </div>
    
  
  <!-- <Foot></Foot> -->
</template>

<script>
import { getDetailFeeds,  } from '../../API/Feedback.js'
import {getUserInfo,getDep} from '../../API/User.js'
import head from '../head.vue'
export default {
  name: 'feedstionDetail',
  props: ['feedId'],
  data() {
    return {
      feedInfo: {},
      userId: this.$store.state.userInfo.id,
      userDep:''
    }
  },
  methods: {
    getDetail() {
      // var id = parseInt(this.$route.params.feedId)
      this.$nextTick(async () => {
        var form ={id:this.userId}
        const { data: res } = await getDetailFeeds(this.feedId)
        
        
        if (res.status === 0) {
          
          this.feedInfo = res.data[0]
          // console.log(this.feedInfo,'feedInfo front')
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
      //   console.log(this.$route.params.schArtId)
    },
    backSch() {
      this.$emit('showFeedInfo', true)
      this.$router.back(-1)
    },
  },
  created() {
    this.getDetail()
  },
  components: {
    Head: head,
  },
  watch: {
    feedId(newId) {
      // this.feedInfo = null
      this.feedId = newId
      this.getDetail()
      // console.log(this.feedInfo,' feed')
    }
  },
  // computed: {
  //  feedId: function(){
  //   return this.getDetail()
  //  }
  // }
}
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
}

.info {
  width: 80%;
  margin: .5rem auto;

  .back {
    float: left;

    span {
      display: inline-block;
      text-align: left;
      height: 37px;
      line-height: 37px;
      font-size: 14px;
      cursor: pointer;
      color: #999aaa;
      padding-left: .1333rem;

    }

    span:hover {
      color: #ff3535
    }

    em {
      margin: .0833rem;
    }
  }

  h1 {
    text-align: center;
    padding-bottom: 20px;
    font-size: .5rem;
    margin: 5px 0;

  }

  .bar {
    width: 100%;
    border-top: 1px solid #dcdcdc;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;

      li {
        font-size: 14px;
        padding: 10px 20px 0 0;
        color: #585858;
      }
    }
  }


}

.content {
  width: 70%;
  margin: 70px auto 20px;
  font-size: 18px;

  p {
    line-height: 40px;
    text-indent: 1em;
  }
}
.tag {
    display: inline-block;
    padding: 4px 10px; /* 内边距，可以根据需要调整 */
    background-color: #4CAF50; /* 绿色背景 */
    color: red; /* 文字颜色 */
    border-radius: 16px; /* 圆角，让边框看起来像长圆框 */
    border: 2px solid #4CAF50; /* 边框颜色和宽度，这里设置为与背景色相同，以形成完整的效果 */
    font-size: 14px; /* 字体大小 */
    line-height: 1; /* 保持文本垂直居中 */
    // margin-left: 70px;
}
.action-bar {
  display: flex;
  /* 改为justify-content: flex-start; 以使按钮靠左对齐 */
  justify-content: flex-start;
  /* 添加间距以与左侧对齐，可根据需要调整像素值 */
  padding-left: 3rem;
  /* 移除按钮之间的默认间隔，实现紧密排布 */
  gap: 0;
}

.action-bar button {
  background-color: #cfe8b5;
  /* 初始浅绿色 */
  color: #333;
  /* 文字颜色 */
  border: none;
  /* 移除边框 */
  padding: 0.5rem 1rem;
  /* 内边距调整大小 */
  font-size: 14px;
  /* 字体大小 */
  cursor: pointer;
  /* 鼠标悬停时显示手型 */
  transition: background-color 0.3s ease;
  /* 过渡效果 */
  border-radius: 4px;
  /* 圆角 */
}

.action-bar button:hover {
  background-color: #9ccc65;
  /* 鼠标悬停时颜色加深 */
}

.action-bar button:active {
  background-color: #66bb6a;
  /* 点击时变为深绿色 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  /* 添加点击阴影效果 */
}

.action-bar .count {
  margin-left: 4px;
  /* 或根据需要调整 */
  font-weight: bold;
}
</style>
